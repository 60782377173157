<template>
    <div class="page1">
        <EButton type="primary" @click="dialogShow" :disabled="tableData.length>0">增加</EButton>
        <ETable :tableTitle="tableTitle"
                :tableData="tableData"
                :needPagination="false"
        >
            <el-table-column
                    fixed="right"
                    label="操作"
                    width="180px"
            >
                <template slot-scope="scope">
                    <div>
                        <EButton type="text" @click="editDialog(scope.row)">
                            修改
                        </EButton>
                        <EButton type="text" @click="remove(scope.row.electricPriceId)">
                            删除
                        </EButton>
                    </div>
                </template>
            </el-table-column>
        </ETable>
        <EDialog :dialogVisible="dialogVisible" :title="title" @handleClose="cancelDialog"
                 width="40%"
                 @handleClick="saveData"
        :disabled="saveDisabled">
            <EForm
                    :formColumns="dialogFormColumns"
                    :rowSize="1"
                    :optionsBtn="false"
                    :actionBtn="true"
                    :formData="dialogForm"
                    ref="form"
                    :searchFlag="false"
                    :labelPosition="labelPosition"
                    :labelWidth="labelWidth"
                    :formRules="formRules"
            >
            </EForm>
        </EDialog>
    </div>
</template>

<script>
  import ETable from '@/components/ETable'
  import Http from "@/service/http";
  import EDialog from '@/components/EDialog'
  import EButton from '@/components/EButton'
  import EForm from '@/components/EForm'
  import {identity_type, status} from '@/assets/js/config'
  import vxRule from "@/assets/js/formValidate";
  import {otherMixin} from "@/components/mixin";
  export default {
    name: 'Login',
    mixins:[otherMixin],
    data() {
      return {
        tableTitle: [
          {
            label: '电费名称',
            prop: 'electricPriceName'
          },
          {
            label: '电费单价（元/度）',
            prop: 'electricUnitPrice'
          },
          {
            label: '电费附加名称',
            prop: 'electricPriceAdditionalName'
          },
          {
            label: '电费附加单价（元/度）',
            prop: 'electricPrice',
          },
          {
            label: '合计单价（元/度）',
            prop: 'electricTotalPrice'
          },
          {
            label: '新建人',
            prop: 'createUserCn'
          },
          {
            label: '新建时间',
            prop: 'createTime'
          },
          {
            label: '最近一次修改人',
            prop: 'updateUserCn'
          },
          {
            label: '修改时间',
            prop: 'updateTime'
          },
        ],
        tableData: [],
        dialogFormColumns: [
          {
            title: '电费名称',
            type: 'text',
            property: 'electricPriceName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            title: '电费单价（元/度）',
            type: 'text',
            property: 'electricUnitPrice',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            title: '电费附加名称',
            type: 'text',
            property: 'electricPriceAdditionalName',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            title: '附加费单价（元/度） ',
            type: 'text',
            property: 'electricPrice',
            placeHolder: '最多可录入20字',
            show: true,
          },
          {
            type: 'actionBtn',
            show: false
          }
        ],
        exportData: {},
        dialogVisible: false,
        labelPosition: 'right',
        labelWidth: '150px',
        dialogForm: {
          electricPriceName: '',
          electricPriceAdditionalName: '',
          electricPrice: '',
          electricUnitPrice: '',
        },
        options: [],
        title: '新增电价设置',
        formRules: {
          electricPriceName: vxRule(true, '', "blur", "电费名称不能为空"),
        },
      }
    },
    watch: {},
    created() {
      this.getData()
    },
    components: {ETable, EDialog, EButton, EForm},
    beforeMount() {

    },
    methods: {
      async cmelectricpriceconfigRemove(ids){
        let res = await Http.cmelectricpriceconfigRemove({ids})
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.getData()
        }
      },
      remove(id){
        this.$messageBox.confirm('确认删除?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cmelectricpriceconfigRemove(id)
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
      },
      editDialog(data) {
        this.dialogVisible=true
        this.dialogForm = {
          electricPriceId:data.electricPriceId,
          electricPriceName: data.electricPriceName,
          electricPriceAdditionalName: data.electricPriceAdditionalName,
          electricPrice: data.electricPrice,
          electricUnitPrice: data.electricUnitPrice,
        }
        this.title='修改电价设置'
      },
      async cmelectricpriceconfigSubmit() {
        this.setDisabled(true)
        let res = await Http.cmelectricpriceconfigSubmit(this.dialogForm)
        if (res.code == 200) {
          this.$message.success(res.msg)
          this.setDisabled(false)
          this.cancelDialog()
          this.getData()
        }
      },
      dialogShow() {
        this.dialogVisible = true
      },
      cancelDialog() {
        this.dialogVisible = false
        this.dialogForm = {
          electricPriceName: '',
          electricPriceAdditionalName: '',
          electricPrice: '',
          electricUnitPrice: '',
        }
        this.title='新增电价设置'
        this.$refs.form.$refs.form.resetFields()
      },
      showDialog() {
        this.dialogVisible = true
      },
      saveData() {
        this.$refs['form'].$refs['form'].validate((valid, object) => {
          if (valid) {
            this.cmelectricpriceconfigSubmit()
          } else {
            return false;
          }
        })
      },
      async getData() {
        let res = await Http.getElePriceList()
        if (res.code == 200) {
          this.tableData = res.data
        }
      }
    }
  }
</script>

<style lang="scss">

</style>
